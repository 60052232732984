<template>
  <div>
    <div v-html="tableData">
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: ["id"],
  data() {
    return {
      tableData: "",
    };
  },
  created() {

    this.$fecth
      .get("school/getSp", {
        params: {
          school_id: this.id,
        },
      })
      .then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tableData = data;
        } else {
          this.showTips(0, msg);
        }
      });
  },
  
  methods: {
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'>
</style>
