module.exports = [
    {
        id: "17",
        name: "财经类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "18",
        name: "理工类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "19",
        name: "农林类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "20",
        name: "综合类",
        state: "1",
        order: "1",
        art: "0",
    },
    {
        id: "21",
        name: "政法类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "22",
        name: "师范类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "23",
        name: "语言类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "24",
        name: "医药类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "26",
        name: "民族类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "27",
        name: "艺术类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "28",
        name: "体育类",
        state: "1",
        order: "0",
        art: "0",
    },
    {
        id: "29",
        name: "军事类",
        state: "1",
        order: "0",
        art: "0",
    },
]