<template>
  <div style="padding: 40px">
    <div class="mian" v-if="tableData">
      <el-row style="font-size: 18px; font-weight: bold">
        <el-col :span="8">录取原则： {{ tableData.lqgz }}</el-col>
        <el-col :span="8" v-if="tableData.danke == '1'" style="color: red"
          >单科限制：是</el-col
        >
        <el-col :span="8" v-else>单科限制:否</el-col>
        <el-col :span="8" v-if="tableData.stsx == '1'" style="color: red"
          >身体受限：是</el-col
        >
        <el-col :span="8" v-else>身体受限:否</el-col>
      </el-row>
      <div  v-if="tableData.qita">{{tableData.qita}}</div>
      <div class="line"></div>
      <div class="lqgz">
        {{ tableData.tllqyz }}
      </div>
      <el-button
        style="float: right; margin-top: 10px"
        size="mini"
        @click="toUrl(tableData.id)"
        >查看详情</el-button
      >
    </div>
    <div class="mian" v-else>
      <el-row style="font-size: 18px; font-weight: bold">
        <el-col :span="8">录取原则：</el-col>
        <el-col :span="8">单科限制：</el-col>

        <el-col :span="8">身体受限：</el-col>
      </el-row>
      <div class="line"></div>
      <div class="lqgz">暂无</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolNews",
  props: ["id"],
  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    id() {
      this.getSchoolNews();
    },
  },
  created() {
    this.getSchoolNews();
  },
  methods: {
    getSchoolNews() {
      this.$fecth
        .get("school_news/getNews", {
          params: {
            school_id: this.id,
          },
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.tableData = data;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    toUrl(val) {
      let routeData = this.$router.resolve({
        path: "/solution",
        query: { nid: val },
      });
      window.open(routeData.href, "_blank");
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 1200px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;

  .title {
    padding-left: 40px;
    margin-top: 30px;
    font-size: 36px;
    color: #555555;

    .label {
      font-size: 20px;
      margin: 0 20px;
    }

    .radio {
      margin-left: 20px;
      color: #eeeeff;
      font-size: 20px;

      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .table {
    margin: 40px 0;
  }
}
.line {
  margin: 20px 0;
  border-bottom: 1px dashed gray;
}
.lqgz {
  letter-spacing: 1px;
  line-height: 24px;
  padding: left 10px;
}
</style>
