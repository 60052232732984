module.exports = [
    {
        id: "11",
        name: "C9",
        state: "1",
        type: "0",
        order: "5",
    },
    {
        id: "5",
        name: "985",
        state: "1",
        type: "0",
        order: "4",
    },
    {
        id: "6",
        name: "211",
        state: "1",
        type: "0",
        order: "3",
    },
    {
        id: "7",
        name: "双一流A",
        state: "1",
        type: "0",
        order: "1",
    },
    {
        id: "12",
        name: "双一流B",
        state: "1",
        type: "0",
        order: "0",
    },
    {
        id: "13",
        name: "国家示范",
        state: "1",
        type: "0",
        order: "0",
    },
    {
        id: "14",
        name: "国家骨干",
        state: "1",
        type: "0",
        order: "0",
    },
    {
        id: "15",
        name: "双高计划",
        state: "1",
        type: "0",
        order: "0",
    },
]