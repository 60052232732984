<template>
  <div class="score">
    <div class="search_item">
      <span style="margin-right: 20px; font-size: 16px">选择科类</span>
      <el-select v-model="is_wenli" size="mini" class="round_select" style="width: 100px" placeholder="请选择"
        @change="onChange">
        <el-option v-for="item in divisionList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
      <span style="margin-left: 40px; margin-right: 20px; font-size: 16px">选择批次</span>
      <el-select v-model="batch" style="width: 100px" size="mini" class="round_select" placeholder="请选择"
        @change="onChange">
        <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </div>
    <div class="table">
      <el-row class="header">
        <el-col :span=2>年份</el-col>
        <el-col :span=3>招生批次</el-col>
        <el-col :span=6>院校</el-col>
        <el-col :span=2>最高分</el-col>
        <el-col :span=2>最低分</el-col>
        <el-col :span=2>线差</el-col>
        <el-col :span=2>平均分</el-col>
        <el-col :span=2>录取数</el-col>
        <el-col :span=2>最低位次</el-col>
      </el-row>
      <template v-if="scoreLineList">
        <el-row v-for="(item, i) in scoreLineList" :key="i" class="tab-row">
          <el-col :span=2>{{ item.year }}</el-col>
          <el-col :span=3>
            <div v-for="i in batchList" :key="i.id">
              <span v-if="item.batch == i.id">
                {{ i.name }}
                <span v-if="item.batch == 4">/{{ item.pici }}</span>
              </span>
            </div>
          </el-col>
          <el-col :span=6>
            {{ item.school_name }}
          </el-col>
          <el-col :span=2>{{ item.max_score | changeZero }} &nbsp;</el-col>
          <el-col :span=2>{{ item.min_score | changeZero }}</el-col>
          <el-col :span=2 v-if="equateList[item.year]">{{ item.min_score - equateList[item.year] |
            filterScore(item.min_score) }}</el-col>
          <el-col :span=2 v-else>-</el-col>
          <el-col :span=2>{{ item.mean_score | changeZero }}</el-col>
          <el-col :span=2>{{ item.matriculate | changeZero }}</el-col>
          <el-col :span=2>{{ item.ranking | changeZero }}</el-col>
        </el-row>
      </template>
      <el-row v-else class="tab-row">
        <el-col :offset="10" :span="4">暂无数据！</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>


export default {
  name: "Score",
  props: ["id", 'batchs'],
  data() {
    return {
      division: 1,
      is_wenli: 1,
      score: 580,
      scoreLineList: [],
      divisionList: [
        { id: 1, name: "文科综合", code: 1, status: 1 },
        { id: 2, name: "理科综合", code: 2, status: 1 },
      ],
      batchList: [
        { id: 1, name: "本科一批", code: 1, status: 1 },
        { id: 2, name: "本科二批", code: 2, status: 1 },
        { id: 3, name: "高职高专批", code: 3, status: 1 },
        { id: 4, name: "其他批次", code: 3, status: 1 },
      ],
      equateList: [],
    };
  },
  filters: {
    filterScore(e, i) {
      if (i == null || i == '' || i == 0) {
        return '-'
      } else {
        return e
      }
    }
  },
  created() {
    this.school_id = this.id;
    this.batch = this.batchs;
    this.getScoreLine();
  },
  methods: {
    getScoreLine() {
      this.scoreLineList = [];
      this.getEquate();
      this.$fecth
        .get("show_school_score/getData", {
          params: {
            school_id: this.school_id,
            is_wenli: this.is_wenli,
            batch: this.batch,
          },
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.scoreLineList = data.list;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    onChange() {
      this.getScoreLine();
    },
    getEquate() {
      this.$fecth
        .get("Equate/searchPrecedence", {
          params: {
            is_wenli: this.is_wenli,
            class: this.batch,
          },
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.equateList = data;
          } else {
            this.showTips(0, msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.score {
  .table {
    padding: 20px;
    text-align: center;
    line-height: 30px;

    .header {
      font-weight: 700;
    }
  }

  .chart {
    margin-top: 20px;
  }
}

.search_item {
  margin-bottom: 20px;
}

.table {
  background: url('../../../assets/img/logo.png');
}
</style>