<template>
  <div style="padding-bottom: 40px">
    <div class="main">
      <div>
        <el-row>
          <el-col :span="17">
            <span style="margin-right: 20px; font-size: 16px">选择科类</span>
            <el-select v-model="division" placeholder="请选择" @change="onChange">
              <el-option v-for="item in divisionList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="table">
        <el-table v-if="tableData" :data="tableData" style="width: 100%">
          <el-table-column prop="specialty_id" align="center" width="100" label="专业代码">
            <template slot-scope="scope">
              <span>{{ scope.row.specialty_id  | interpo}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="specialty_name" align="left" label="专业名称" width="300" show-overflow-tooltip />
          <el-table-column prop="pici" align="center" label="招生批次" width="150" show-overflow-tooltip />
          <el-table-column prop="plan_num" align="center" width="78" label="专业计划" />
          <el-table-column prop="tuition" align="center" width="78" label="收费标准" />
          <el-table-column prop="beizhu" align="center" label="备注" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.beizhu}}{{ scope.row.remarks}}{{ scope.row.dingxiang}}{{ scope.row.address}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetPlant",
  props: ["id"],
  data() {
    return {
      tableData: [],
      division: "1",
      divisionList: [],
      batch: "1",
      batchList: [
        "本科一批",
        "本科二批",
        "高职高专批",
        "国家专项计划本科批",
        "地方专项计划本科批",
        "提前批军队本科",
        "专科提前批直招士官",
        "提前批公安本科",
        "提前批公安专科",
        "提前批普通本科",
        "专科提前批航海",
        "提前批体育本科",
        "提前批体育专科",
        "艺术本科A段",
        "艺术本科B段",
        "提前批艺术专科",
        "专科提前批空乘等",
        "提前批司法本科",
        "提前批司法专科",
        "专科提前批医学类",
        "提前批军队招飞",
        "高职提前单招批",
        "特殊类型批",
        "对口生本科批",
        "对口生专科批",
        "专升本批",
        "艺术本科提前批",
        "专科提前批全科师范",
      ],
      value: null,
      options: [],
      category: "1", // 类别
      categoryList: [], // 类别列表
    };
  },
  methods: {
    getScoreLine(id) {
      this.$fecth
        .get("enrollment_plan/getData", {
          params: {
            school_id: id,
            is_wenli: this.division,
          },
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.tableData = data;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    selectChange(val) {
      this.id = val;
      this.getScoreLine(val);
    },
    onChange(val) {
      this.division = val;
      this.getScoreLine(this.id);
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
  mounted() {
    // 获取批次/科类列表
    this.divisionList = [
      { id: "1", name: "文科综合", code: "1", status: "1" },
      { id: "2", name: "理科综合", code: "2", status: "1" },
    ];
    this.getScoreLine(this.id);
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;

  .title {
    padding-left: 40px;
    margin-top: 30px;
    font-size: 36px;
    color: #555555;

    .label {
      font-size: 20px;
      margin: 0 20px;
    }

    .radio {
      margin-left: 20px;
      color: #eeeeff;
      font-size: 20px;

      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .table {
    margin: 40px 0;
  }
}
</style>
