<template>
  <div class="page">
    <div class="main">
      <SearchCollege :condition="data" @onSearch="onSearch" />
      <div v-loading="loading">
        <CollegeList
          v-for="(item, i) in list"
          :key="i"
          :item="item"
        ></CollegeList>
        <div class="main">
          <template>
            <div>
              <el-pagination
                @current-change="handleCurrentChange"
                :page-size="this.tables.pageSize"
                :hide-on-single-page="true"
                layout=" prev, pager, next"
                :total="this.tables.total"
                :current-page="this.currentPage"
              >
              </el-pagination>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchCollege from "@/components/SearchCollege";
import CollegeList from "./components/CollegeList";
import city_data_array from "@/assets/data/city_data.js";
import school_type_array from "@/assets/data/school_type.js";
import school_tip_array from "@/assets/data/school_tip_data.js";

export default {
  name: "",
  components: {
    SearchCollege,
    CollegeList,
  },
  data() {
    return {
      data: [],
      list: [],
      currentPage: 1,
      loading: false,
      tables: {
        pageSize: 20,
        total: 0,
      },
    };
  },
  
  
  mounted() {
    const res = [];
    res.city = city_data_array;
    res.nature = [
      {
        id: "1",
        name: "公办",
        state: "1",
      },
      {
        id: "2",
        name: "民办",
        state: "1",
      },
      {
        id: "3",
        name: "中外合作办学",
        state: "1",
      },
      {
        id: "4",
        name: "内地与港澳台地区合作办学",
        state: "1",
      },
    ];
    res.schoolTip = school_tip_array;
    res.schoolType = school_type_array;
    this.data = res;
  },
  methods: {
    onSearch(data) {
      this.loading = true;
      this.tables.pageNum = 1;
      this.currentPage = 1;
      this.check = data;
      this.getCollege(data);
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.tables.pageNum = val;
      this.getCollege(this.check);
    },

    getCollege(obj) {
      if (obj.name > 1) {
        obj.school_id = obj.name;
        delete obj.name;
      } else {
        delete obj.school_id;
      }
      obj.pageNum = this.tables.pageNum;
      obj.pageSize = this.tables.pageSize;
      this.$fecth.post("school/getSchoolList", obj).then((res) => {
        this.loading = false;
        const { code, msg, data } = res;
        if (code == 200) {
          this.list = data.lists;
          this.tables.total = data.total;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'>
</style>
